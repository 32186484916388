import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';

// Importing images
import header from '../Img/Our_team/our_clients.png';
import subheader from '../Img/Our_team/our_clients_subheader.png';
import client_1 from '../Img/Our_team/1.png';
import client_2 from '../Img/Our_team/2.png';
// Add imports for client_3 through client_20 as needed
import client_3 from '../Img/Our_team/3.png';
import client_4 from '../Img/Our_team/4.png';
import client_5 from '../Img/Our_team/5.png';
import client_6 from '../Img/Our_team/6.png';
import client_7 from '../Img/Our_team/7.png';
import client_8 from '../Img/Our_team/8.png';
import client_9 from '../Img/Our_team/9.png';
import client_10 from '../Img/Our_team/10.png';
import client_11 from '../Img/Our_team/11.png';
import client_12 from '../Img/Our_team/12.png';
import client_13 from '../Img/Our_team/13.png';
import client_14 from '../Img/Our_team/14.png';
import client_15 from '../Img/Our_team/15.png';
import client_16 from '../Img/Our_team/16.png';
import client_17 from '../Img/Our_team/17.png';
import client_18 from '../Img/Our_team/18.png';
import client_19 from '../Img/Our_team/19.png';
import client_20 from '../Img/Our_team/20.png';
// Continue importing up to client_20

const clients = [
  client_1, client_2, client_3, client_4, client_5, client_6, client_7, client_8, client_9,
  client_10, client_11, client_12, client_13, client_14, client_15, client_16, client_17, client_18, client_19, client_20
];

const Header = styled('img')(({ theme }) => ({
  height: 'auto',
  maxWidth: '100%',
  marginBottom: '2rem', // Add margin to separate the header from the client logos
  [theme.breakpoints.down('md')]: {
    maxWidth: '80%',
    marginBottom: '2rem', // Adjust for smaller screens
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '80%',
    marginBottom: '2rem', // Adjust for smaller screens
  },
}));

const SubHeader = styled('img')(({ theme }) => ({
  height: '100%',
  width: '50%',
  maxWidth: '100%',
  marginTop: '2rem', // Add margin to separate the header from the client logos
  [theme.breakpoints.down('md')]: {
    maxWidth: '80%',
    marginBottom: '2rem', // Adjust for smaller screens
  },
  [theme.breakpoints.down('lg')]: {
    maxWidth: '80%',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#000',
  fontSize: '1.5rem',
  lineHeight: '1.5',
  //fontWeight: 600,
  fontFamily: "industry-inc-base",

  maxWidth: '60%',
  textAlign: 'center', // Center the text
  margin: '2rem 0', // Add margin to create space around the text
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    maxWidth: '80%',
  },
}));


const ProductContainer = styled(Box)(({ theme }) => ({
  height: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem 0', // Add padding to create space
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    padding: '1rem 0',
  },
}));

const ClientLogo = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxHeight: '125px', // Ensure logos fit well within the grid cells
  objectFit: 'contain', // Maintain aspect ratio
  [theme.breakpoints.down('md')]: {
    maxHeight: '50px', // Ensure logos fit well within the grid cells for smaller screens
  },
}));

const OurClients = () => {
  return (
    <Container disableGutters maxWidth="100vw" sx={{ width: '100%', height: 'auto', padding: '0%' }}>
      <ProductContainer id="our-clients">
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ height: 'auto', width: '100%' }}>
          <Header src={header} alt="Our Clients Header" />
          <StyledTypography>
            HUNDREDWEIGHT ICE IS PROUDLY SERVED AND ENJOYED AT THESE FINE ESTABLISHMENTS (and many more)…
          </StyledTypography>
        </Grid>
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={(theme) => ({
          height: 'auto',
          width: '90%',
          padding: '2%',
          paddingLeft: '10%',
          [theme.breakpoints.down('md')]: {
            width: '100%',
            paddingLeft: '0%',
            paddingBottom: '5%',
          },
          [theme.breakpoints.down('lg')]: {
            width: '100%',
            paddingLeft: '0%',
            paddingBottom: '5%',
          },
        })}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            {clients.map((client, index) => (
              <Grid item xs={4} sm={3} md={3} key={index}>
                <ClientLogo src={client} alt={`Client ${index + 1}`} />
              </Grid>
            ))}
          </Grid>
          {/* <SubHeader src={subheader} alt="Enquire about custom cuts to fit your glassware" /> */}
          <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ padding: '2rem 0', width: '100%' }}>
            <StyledTypography>
              Inquire about custom cuts to fit your glassware!
            </StyledTypography>
        </Grid>
        </Grid>
      </ProductContainer>
    </Container>
  );
};

export default OurClients;
